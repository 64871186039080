@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: linear-gradient(to right, #0f172a, #1e1b4b);
  color: white;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}